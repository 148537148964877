import { VideoApi } from "../Api";
import { useQuery } from "@tanstack/react-query";
export const useMapData = ()=>{
    const videoApi = new VideoApi();
    const useMapVideos = (boundingBox, zoom)=>{
        return useQuery([
            "videos",
            "map",
            boundingBox,
            zoom
        ], async ()=>await videoApi.getMapVideos(boundingBox, zoom), {
            keepPreviousData: true,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            enabled: Array.isArray(boundingBox)
        });
    };
    return {
        useMapVideos
    };
};
