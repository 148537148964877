import { Geometry, Point } from 'geojson';
import { ClusterFeature, PointFeature } from 'supercluster';
import React from 'react';
import {
  FitBoundsOptions,
  LngLatBoundsLike,
  MapRef,
  ViewState,
  ViewStateChangeEvent,
} from 'react-map-gl';
import mapboxgl from 'mapbox-gl';

export function isPointType(geometry: Geometry): geometry is Point {
  return (geometry as Point).coordinates !== undefined;
}

export type ICluster<T = object> = Cluster & T;

export type Cluster = {
  cluster: boolean;
  cluster_id: number;
  point_count: number;
  point_count_abbreviated: number;
};

export type ClusterOrPoint<T> = ClusterFeature<T> | PointFeature<T>;

export type IPoint<T> = T;

export function isCluster<T = unknown>(
  cluster: ICluster | IPoint<T>
): cluster is ICluster {
  return (cluster as ICluster).point_count !== undefined;
}

export interface IMapProps {
  children?: React.ReactNode;
  initialViewState?: Partial<ViewState> & {
    longitude?: number;
    latitude?: number;
    zoom?: number;
    bounds?: LngLatBoundsLike | undefined;
    fitBoundsOptions?: FitBoundsOptions | undefined;
  };
  onMapLoad?: (map: MapRef) => void;
  onMoveEnd?: (e: ViewStateChangeEvent) => void;
  onZoomEnd?: (e: ViewStateChangeEvent) => void;
  onWheel?: (e: mapboxgl.MapWheelEvent) => void;
  scrollZoom?: boolean;
  style?: React.CSSProperties;
  mapStyle: string;
  mapToken: string;
  popup?: React.ReactNode;
  maxZoom?: number;
}
