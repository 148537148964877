import { useEffect, useRef, useState } from "react";
export const useWebWorkerCluster = (props)=>{
    const workerRef = useRef(null);
    const [data, setData] = useState([]);
    useEffect(()=>{
        workerRef.current = new Worker(new URL("./clusters.worker.ts", import.meta.url));
        if (!workerRef.current) return;
        workerRef.current.onmessage = (event)=>setData(event.data);
        workerRef.current.onerror = (error)=>console.error(error);
        workerRef.current.postMessage(props);
        return ()=>{
            var ref;
            (ref = workerRef.current) === null || ref === void 0 ? void 0 : ref.terminate();
        };
    }, [
        props.zoom,
        props.points,
        ...props.bbox
    ]);
    return {
        data,
        options: props
    };
};
