import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import React, { useRef } from 'react';
import { Map as MapboxMap, MapRef } from 'react-map-gl';
import { IMapProps } from './types';

export const Map = ({
  initialViewState,
  style,
  mapToken,
  mapStyle,
  onMapLoad,
  onMoveEnd,
  onZoomEnd,
  children,
  maxZoom = 22,
  scrollZoom,
  onWheel,
  popup,
}: IMapProps) => {
  const mapRef = useRef<MapRef | null>(null);

  const onLoad = React.useCallback(() => {
    if (mapRef.current) {
      onMapLoad && onMapLoad(mapRef.current);
    }
  }, []);
  return (
    <MapboxMap
      id="map"
      ref={mapRef}
      onLoad={onLoad}
      scrollZoom={scrollZoom}
      onWheel={onWheel}
      onMoveEnd={onMoveEnd}
      onZoomEnd={onZoomEnd}
      maxZoom={maxZoom}
      mapStyle={mapStyle}
      mapboxAccessToken={mapToken}
      style={{ height: 'calc(100vh - 84px)', ...style }}
      initialViewState={{
        longitude: -98.5795,
        latitude: 39.8283,
        zoom: 10,
        ...initialViewState,
      }}
    >
      {popup}
      {children}
    </MapboxMap>
  );
};
